.voyage-planner-container{
    display: flex;
    flex-direction: row;
}

.voyage-route-container{
    width: 20%;
    height: 100vh;
    border-left: 2px solid grey;
    display: flex;
    flex-direction: column;
}
#port-select{
    width: 90%;
}
.port-locator{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.5rem;
}

.port-select {
    margin: 0.5rem 0;
}

.voyage-listing{

}

.voyage-map-container{
    width: 80%;
    height: 100vh;
}

#route-map{
  width: 100%;
  height: 90%;
}
#map-container{
    width: 100%;
    height: 100%;
}
