.voyage-listing {

}

.details-container {

}

.details-container .ports-list {
    border: 1px solid #8ABEB1;
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #003D59;
}

.details-container .voyage-duration {
    margin: 0.5rem;
}